import '@radix-ui/themes/styles.css';
import { Table,Container,TextField, Button, Badge } from '@radix-ui/themes';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import React, { Component } from 'react';
import axios from 'axios'
import CONFIG from './config';

class Records extends Component {

  state = { records: [], resRecords: []};


  handleDelRecord = (record) => {
    axios({
      method: 'DELETE',
      url:  CONFIG["DOMAIN"] + '/records/' + record["key"],
    }).then((response) =>  {
      const updatedRecords = this.state.records.filter(r => r.key !== record.key);
      const updatedResRecords = this.state.resRecords.filter(r => r.key !== record.key);
      
      this.setState({
        records: updatedRecords,
        resRecords: updatedResRecords
      });
    });
  };

  handleSearchRecordChange = (e) => {
    if (e.target.value !== "") {
      this.setState({resRecords: this.state.records.filter(record => record["zone"].includes(e.target.value) )});
    } else {
      this.setState({resRecords: this.state.records})
    }
  };

  myLoad = () => {
    axios({
      method: 'GET',
      url:  CONFIG["DOMAIN"] + '/records',
    }).then((response) =>  {
      this.setState({
        records: response.data
      });
      if (this.state.resRecords.length === 0)
      {
        this.setState({
          resRecords: response.data
        });
      }
    });
  }
  componentDidMount() {
    this.myLoad()
  }


  render() {
    return (
      <Container size="6">
        <Container size="6">
          <TextField.Root placeholder="Search records zone..."  onChange={this.handleSearchRecordChange}>
            <TextField.Slot>
              <MagnifyingGlassIcon height="16" width="16" />
            </TextField.Slot>
          </TextField.Root>
        </Container>
        <Container size="6">
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Zone</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>SubDomain</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>FieldType</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Target</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Provider</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Terraform Apply</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Action</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
  
          <Table.Body>

          {this.state.resRecords.map(record => {
            return (
              <Table.Row>
                <Table.RowHeaderCell style={{ maxWidth: '200px' }} >{record["zone"]}</Table.RowHeaderCell>
                <Table.Cell style={{ maxWidth: '100px' }} >{record["subdomain"]}</Table.Cell>
                <Table.Cell style={{ maxWidth: '100px' }} >{record["fieldType"]}</Table.Cell>
                <Table.Cell style={{ maxWidth: '400px' }}>{record["target"]}</Table.Cell>
                <Table.Cell style={{ maxWidth: '100px' }} >
                    <Badge color="blue">{record["provider"].toUpperCase()}</Badge>
                </Table.Cell>
                <Table.Cell style={{ maxWidth: '150px' }} >
                  { !record["apply"] && <Badge color="orange">Waiting schedule</Badge>}
                  { record["apply"] &&  <Badge color="green">Complete</Badge>}
                </Table.Cell>
                <Table.Cell style={{ maxWidth: '150px' }} >
                  <Button color="red" onClick={() => {this.handleDelRecord(record)} }>
                    Supprimer
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })}
          </Table.Body>
        </Table.Root>
        </Container>
      </Container>
    );
  }
}

export default Records;
